import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "gofish",
  casinoId: 76,
  GA_TRACKING:'G-WDX7KPRYM8',

  rivalChatGroupName: "Go Fish",
  prettyName: "Casino Go Fish",
  contactEmail: "support@casinogofish.com",
  docsEmail: "documents@casinogofish.com",


  //SEO
  metaDescription: "Online casino gaming at Go Fish Casino powered by Rival Gaming! Play top slots, latest slot machines, table games, and live dealer gambling iGames with generous Welcome- and deposit bonuses and rewarding VIP loyalty program. Win big, play securely, get secure transactions and fast payment! Even bitcoin.",
  logoName: "casino-gofish-online-casino-logo.png",
  logoAltText: "Go Fish Casino online logo with playful blue text and fish graphic known for fun slots games casino rewards and fishing-themed bonuses."
};


